import styled, { css } from 'styled-components'
import { media } from '@theme'
import { Link } from 'gatsby'


export const Wrapper = styled.div`
  ${media.medium} {
    /* Dirty hack to hide the top shadow of the mobile menu */
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 101;
      left: 0;
      right: 0;
      bottom: 0;
      height: 10px;
      
    }
  }
`

interface IHambuguer {
  show: boolean
}

export const Hamburguer = styled.button`
  display: none;
  position: relative;

  width: 2.5em;
  height: 2.5em;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;

    &:before,
    &:after {
      opacity: 0.8;
    }
  }

  &:before,
  &:after {
    content: '';
    transition: all 0.3s;
    height: 2px;
    width: 100%;
    left: 0;
    position: absolute;
  }

  &:before {
    top: 0.75em;

  }

  &:after {
    bottom: 0.75em;

  }

  ${(p: IHambuguer) =>
    p.show &&
    css`
      &:before {
        transition: all 0.3s;
        top: 1.15em;
        transform: rotate(45deg);
      }

      &:after {
        transition: all 0.3s;
        bottom: 1.15em;
        transform: rotate(135deg);
      }
    `}

  ${media.medium} {
    display: block;
  }
`



export const NavWrapper = styled.nav`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2em;

  ${media.medium} {
    position: absolute;
    z-index: 100;
    overflow: hidden;
    right: 0;
    left: 0;
    padding: 0 1.9em;
    display: block;
    text-align: right;    
  }
`
