/* eslint-disable */
import React, { RefObject } from 'react'

import { Link } from 'gatsby'
import * as S from './styled'

import styled from 'styled-components'

interface INavigationState {
  mobileMenu: boolean
}

class Navigation extends React.Component<
  { forceClose: boolean },
  INavigationState
> {
  menuWrapperRef: RefObject<HTMLElement> = React.createRef()
  
  state = { mobileMenu: false }

  toggleMenu = () => this.setState(state => ({ mobileMenu: !state.mobileMenu }))

  

  getItemsHeight = (): number => {
    if (this.menuWrapperRef.current) {
      return Array.prototype.slice
        .call(this.menuWrapperRef.current.childNodes)
        .reduce((acc, child) => acc + child.offsetHeight, 0)
    }

    return 0
  }

  componentDidUpdate() {
    if (this.props.forceClose) {
      this.setState({ mobileMenu: false })
    }
  }

  render() {
    const menuVis = this.state.mobileMenu ? 'showmenu' : 'hidemenu';
    const activehoverhue = this.props.activeStateColour 
    
    
    return (
      <S.Wrapper className="nav-wrp">
        <S.NavWrapper className={`${menuVis}`}
          show={this.state.mobileMenu}
          itemsHeight={this.getItemsHeight()}
          ref={this.menuWrapperRef}
        >
          <Link
            to="/covid-19/"
            activeClassName="active activec"
            
            partiallyActive={true}
            className="covid-menu"
          >
            Low Touch Economy{' '}
          </Link>
          <StyledLink
            to="/work/"
            activeClassName="active"
            partiallyActive={true}
            activehoverhue={activehoverhue} 
          >
            Work
          </StyledLink>
          <StyledLink
            to="/clients/"
            activeClassName="active"
            partiallyActive={true}
            activehoverhue={activehoverhue} 
          >
            Clients
          </StyledLink>
          <StyledLink
            to="/services/"
            activeClassName="active"
            partiallyActive={true}
            activehoverhue={activehoverhue} 
          >
            Services
          </StyledLink>
          <StyledLink
            to="/about/"
            activeClassName="active"
            partiallyActive={true}
            activehoverhue={activehoverhue} 
          >
            About
          </StyledLink>

          <StyledLink
            to="/careers/"
            activeClassName="active"
            partiallyActive={true}
            activehoverhue={activehoverhue} 
          >
            Careers
          </StyledLink>
          
          <StyledLink
            to="/read"
            activeClassName="active"
            partiallyActive={true}
            activehoverhue={activehoverhue} 
          >
            Read
          </StyledLink>
          <StyledLink
            to="/contact"
            activeClassName="active"
            partiallyActive={true}
            activehoverhue={activehoverhue} 
          >
            Contact
          </StyledLink>
          {/* {this.renderLinks(items)} */}
          <section id="mobile-social">
            <p>Follow us</p>
            <div className="links">
              <a target="_blank" title="" href="https://www.instagram.com/team.urbian/"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M15,2.7c4,0,4.48,0,6.06.09a8.3,8.3,0,0,1,2.79.52,4.5,4.5,0,0,1,1.72,1.12,4.5,4.5,0,0,1,1.12,1.72,8.3,8.3,0,0,1,.52,2.79c.07,1.58.09,2.05.09,6.06s0,4.48-.09,6.06a8.3,8.3,0,0,1-.52,2.79,5,5,0,0,1-2.84,2.84,8.3,8.3,0,0,1-2.79.52c-1.58.07-2,.09-6.06.09s-4.48,0-6.06-.09a8.3,8.3,0,0,1-2.79-.52,4.5,4.5,0,0,1-1.72-1.12,4.5,4.5,0,0,1-1.12-1.72,8.3,8.3,0,0,1-.52-2.79C2.72,19.48,2.7,19,2.7,15s0-4.48.09-6.06a8.3,8.3,0,0,1,.52-2.79A4.5,4.5,0,0,1,4.43,4.43,4.5,4.5,0,0,1,6.15,3.31a8.3,8.3,0,0,1,2.79-.52C10.52,2.72,11,2.7,15,2.7M15,0c-4.07,0-4.58,0-6.18.09a11,11,0,0,0-3.65.7A7.23,7.23,0,0,0,2.52,2.52,7.23,7.23,0,0,0,.79,5.17a11,11,0,0,0-.7,3.65C0,10.42,0,10.93,0,15s0,4.58.09,6.18a11,11,0,0,0,.7,3.65,7.23,7.23,0,0,0,1.73,2.65,7.23,7.23,0,0,0,2.65,1.73,11,11,0,0,0,3.65.7c1.6.07,2.11.09,6.18.09s4.58,0,6.18-.09a11,11,0,0,0,3.65-.7,7.7,7.7,0,0,0,4.38-4.38,11,11,0,0,0,.7-3.65C30,19.58,30,19.07,30,15s0-4.58-.09-6.18a11,11,0,0,0-.7-3.65,7.23,7.23,0,0,0-1.73-2.65A7.23,7.23,0,0,0,24.83.79a11,11,0,0,0-3.65-.7C19.58,0,19.07,0,15,0Zm0,7.3A7.7,7.7,0,1,0,22.7,15,7.7,7.7,0,0,0,15,7.3ZM15,20a5,5,0,1,1,5-5A5,5,0,0,1,15,20ZM24.81,7A1.8,1.8,0,1,1,23,5.19,1.8,1.8,0,0,1,24.81,7Z"/></g></g></svg></a>
              <a
                href="https://dribbble.com/Urbian"
                title="Find us on Dribbble"
                target="_blank"
                rel="noopener noreferrer"
                className="styled__SocialLink-gWhCUO iObwtR"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M21.63,24.63c-.63-2.88-1.38-5.54-2.13-7.87a15.91,15.91,0,0,1,2.37-.17h0a20.7,20.7,0,0,1,4.59.56,11.67,11.67,0,0,1-4.86,7.48M15,26.7a11.61,11.61,0,0,1-7-2.31,16.71,16.71,0,0,1,2.73-3.33,15.88,15.88,0,0,1,6.07-3.69,58.47,58.47,0,0,1,2.32,8.57A11.6,11.6,0,0,1,15,26.7M3.3,15v-.16H4.59a37.09,37.09,0,0,0,10.62-1.51c.22.49.45,1,.68,1.54a18.75,18.75,0,0,0-6.61,3.87A21.76,21.76,0,0,0,6.06,22.5,11.57,11.57,0,0,1,3.3,15M9.83,4.5A36.93,36.93,0,0,1,14,10.88a33.42,33.42,0,0,1-9.24,1.3H4.58c-.37,0-.68,0-.94,0A11.71,11.71,0,0,1,9.83,4.5M15,3.3a11.62,11.62,0,0,1,7.29,2.56A15.19,15.19,0,0,1,16.54,10a44.87,44.87,0,0,0-4-6.4A12.1,12.1,0,0,1,15,3.3m9.19,4.47a11.78,11.78,0,0,1,2.5,6.68,23.08,23.08,0,0,0-4.79-.53h0a20.32,20.32,0,0,0-3.4.29c-.25-.63-.49-1.24-.77-1.82a18.21,18.21,0,0,0,6.47-4.62M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Z"/></g></g></svg>
              </a>
              <a
                href="https://twitter.com/urbian"
                title="Find us on Twitter"
                target="_blank"
                className=""
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 24.38"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_2-2" data-name="Layer 2"><g id="Layer_1-2-2" data-name="Layer 1-2"><path className="cls-1" d="M26.92,6.08v.8c0,8.13-6.19,17.5-17.5,17.5A17.47,17.47,0,0,1,0,21.62a14.66,14.66,0,0,0,1.48.07,12.31,12.31,0,0,0,7.64-2.63A6.15,6.15,0,0,1,3.37,14.8a8.93,8.93,0,0,0,1.16.1,7.34,7.34,0,0,0,1.62-.21,6.15,6.15,0,0,1-4.93-6V8.58A6.31,6.31,0,0,0,4,9.36,6.18,6.18,0,0,1,2.09,1.13,17.54,17.54,0,0,0,14.77,7.57a7.51,7.51,0,0,1-.15-1.41A6.15,6.15,0,0,1,25.26,2,12.06,12.06,0,0,0,29.16.47a6.18,6.18,0,0,1-2.7,3.39,12.46,12.46,0,0,0,3.54-1A13.29,13.29,0,0,1,26.92,6Z"/></g></g></g></g></svg>
              </a>
            </div>
          </section>
        </S.NavWrapper>
          
        <S.Hamburguer
          className="menu-toggle-icon"
          aria-expanded={this.state.mobileMenu}
          show={this.state.mobileMenu}
          onClick={this.toggleMenu}
        />
      </S.Wrapper>
    )
  }
}

const StyledLink = styled(Link)`
  :before {
    background: ${props => props.activehoverhue} !important;
  }
  
`



export default Navigation