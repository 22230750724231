/* eslint-disable */
import React from 'react'

import { Logo } from '../UI/'

import * as S from './styled'
import Navigation from './Navigation'



const Header: React.FC<{ isBlogPage?: boolean; children: React.ReactNode }> = ({
  children,
  isBlogPage,
  activeStateTextColour,
  activeStateColour,
}) => {
  
  
  const [shouldClose, setShouldClose] = React.useState(false)
  
  // reset state
  React.useEffect(() => {
    if (shouldClose) {
      setShouldClose(false)
    }
  }, [shouldClose])
  
  return (
    <S.Header>
      <S.Wrapper>
        <S.Container className="nav-container">
          <div>
            <S.LogoLink to="/" title="Go to home page">
              <Logo />
            </S.LogoLink>
          </div>

          <Navigation 
            activeStateColour={activeStateColour} 
            activeStateTextColour={activeStateTextColour} 
            forceClose={shouldClose} 
          />
        </S.Container>
      </S.Wrapper>

      {children}
    </S.Header>
  )
}

export default React.memo(Header)
