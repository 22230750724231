/* eslint-disable */
import React from 'react'


import Header from '../Header'
import Footer from '../Footer'
import 'bootstrap/dist/css/bootstrap.min.css'

interface ILayoutProps {
  children: React.ReactNode
  renderHeaderChildren?: React.ReactNode
  activeStateTextColour?:string
  activeStateColour: string
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  renderHeaderChildren,
  activeStateColour,
}) => {
  
  return (
    <>
        <Header activeStateColour={activeStateColour}>{renderHeaderChildren}</Header>
          {children}  
        <Footer />
    </>
  )
}

export default Layout
