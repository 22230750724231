/* eslint-disable */
import React from 'react'
import styled from 'styled-components'


interface ILogo {
  color?: string
}



const Logo: React.FC<ILogo> = ({ color, ...props }) => (
  <svg
    {...props}
    color={color}
    width="96"
    height="25"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 155.59 35.09"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M21.73,21.34c0,2.57-.52,4.45-1.54,5.65s-2.89,1.8-5.58,1.8a8.61,8.61,0,0,1-3.68-.65,5.34,5.34,0,0,1-3.14-4.06,15.51,15.51,0,0,1-.24-2.74V0H0V21.34q0,7,3.82,10.39t10.79,3.36c4.59,0,8.17-1.13,10.77-3.39S29.27,26,29.27,21.34V0H21.73Z" />
        <path d="M45.14,9.18a9.22,9.22,0,0,0-2.26,1.08A8.63,8.63,0,0,0,41,12a8.51,8.51,0,0,0-1.3,2.13h-.09V9.47H33.12V34.32h6.82V23.12A13.94,13.94,0,0,1,40.28,20a6.79,6.79,0,0,1,1.13-2.53,5.47,5.47,0,0,1,2.09-1.7,7.22,7.22,0,0,1,3.17-.63c.42,0,.85,0,1.3.07s.83.11,1.16.17V9a5.41,5.41,0,0,0-1.49-.24,8.32,8.32,0,0,0-2.5.38" />
        <path d="M70.66,24.9a7.89,7.89,0,0,1-1.06,2.55,5.46,5.46,0,0,1-1.8,1.75,5,5,0,0,1-2.62.65,5.09,5.09,0,0,1-2.6-.65,5.43,5.43,0,0,1-1.82-1.75A7.89,7.89,0,0,1,59.7,24.9a13.28,13.28,0,0,1-.34-3,13.52,13.52,0,0,1,.34-3,7.89,7.89,0,0,1,1.06-2.55,5.53,5.53,0,0,1,1.82-1.75,5.09,5.09,0,0,1,2.6-.65,5,5,0,0,1,2.62.65,5.56,5.56,0,0,1,1.8,1.75,7.89,7.89,0,0,1,1.06,2.55,13.52,13.52,0,0,1,.34,3,13.28,13.28,0,0,1-.34,3m3.87-13a9.48,9.48,0,0,0-3.29-2.35,9.34,9.34,0,0,0-3.7-.77,11.33,11.33,0,0,0-4.45.89A7.24,7.24,0,0,0,59.7,12.5h-.1V0H52.78V34.32h6.49V31.15h.09a6.24,6.24,0,0,0,3.27,2.93,13.06,13.06,0,0,0,4.91.87,9.45,9.45,0,0,0,7-3.13,12,12,0,0,0,2.38-4.06,17,17,0,0,0,.91-5.89A17,17,0,0,0,76.91,16a12,12,0,0,0-2.38-4.06" />
        <rect x="81.38" width="6.83" height="5.62" />
        <rect x="81.38" y="9.47" width="6.83" height="24.85" />
        <path d="M108.1,24.71a13.14,13.14,0,0,1-.12,1.54,5.11,5.11,0,0,1-.64,1.89,4.45,4.45,0,0,1-1.64,1.61,6,6,0,0,1-3.12.68,8.25,8.25,0,0,1-1.59-.15,3.82,3.82,0,0,1-1.35-.5,2.64,2.64,0,0,1-.91-1,3.23,3.23,0,0,1-.34-1.53,3.37,3.37,0,0,1,.34-1.59,3.07,3.07,0,0,1,.89-1,4.28,4.28,0,0,1,1.3-.65,13.39,13.39,0,0,1,1.51-.39c.55-.09,1.09-.17,1.64-.24s1.06-.14,1.56-.24a9.09,9.09,0,0,0,1.39-.36,2.81,2.81,0,0,0,1.08-.6Zm6.83,4.08V15.86a6,6,0,0,0-1-3.63,6.91,6.91,0,0,0-2.59-2.13,11.57,11.57,0,0,0-3.51-1A29,29,0,0,0,104,8.8a20.32,20.32,0,0,0-4.11.41,11.73,11.73,0,0,0-3.68,1.37,8.17,8.17,0,0,0-2.69,2.54,7.84,7.84,0,0,0-1.2,4h6.82a3.87,3.87,0,0,1,1.35-2.88,5.17,5.17,0,0,1,3.17-.87,11.47,11.47,0,0,1,1.71.12,3.75,3.75,0,0,1,1.39.48,2.67,2.67,0,0,1,1,1,3.66,3.66,0,0,1,.36,1.76,1.83,1.83,0,0,1-.62,1.61,4.58,4.58,0,0,1-1.83.84,17.76,17.76,0,0,1-2.64.43q-1.49.15-3,.39a27.16,27.16,0,0,0-3,.65,8.59,8.59,0,0,0-2.69,1.22,6.32,6.32,0,0,0-1.93,2.19,7.3,7.3,0,0,0-.74,3.48,7.83,7.83,0,0,0,.65,3.32A6.24,6.24,0,0,0,94,33.17a7.47,7.47,0,0,0,2.69,1.34A12.17,12.17,0,0,0,100,35a15.43,15.43,0,0,0,4.52-.68,8.83,8.83,0,0,0,3.84-2.35c0,.41.09.82.17,1.22a11.34,11.34,0,0,0,.31,1.18h6.93a5.66,5.66,0,0,1-.68-2.31,25.46,25.46,0,0,1-.19-3.22" />
        <path d="M140.74,11.61A6.67,6.67,0,0,0,138,9.54a11.75,11.75,0,0,0-4.5-.74,9.84,9.84,0,0,0-4.23,1,7.87,7.87,0,0,0-3.36,3.15h-.15V9.47h-6.48V34.32h6.82v-13c0-2.54.42-4.35,1.25-5.46a4.72,4.72,0,0,1,4-1.66,3.72,3.72,0,0,1,3.41,1.52,8.7,8.7,0,0,1,1,4.59v14h6.83V19a20.24,20.24,0,0,0-.41-4.21,7.84,7.84,0,0,0-1.42-3.22" />
        <rect x="148.05" y="26.92" width="7.55" height="7.4" />
      </g>
    </g>
  </svg>
)

export default Logo
